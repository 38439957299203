<template v-cloak>
  <div>
    <div class="create-container">
      <div class="create-nav-wrapper">
        <div class="create-nav app-inner-container" style="border-bottom: 1px solid #f5f7fa;">
          <div class="create-nav-left">
            <div class="nav-home" @click="onGoBackClick">
              <svg-icon icon-class="home" />
            </div>
            <div class="separator"></div>
            <div class="nav-title">
              <el-input v-model="name" v-focus placeholder="请输入表单名称" class="title_editor_input" ref="nameInput" @input="oninputName" @focus="onFocusName" @blur="onBlurName" v-if="isInput"></el-input>
              <span style="color: #3476F0;" ref="fixName" @click="onClickNameEvent" v-else>{{name}}</span>
              <!-- <svg-icon icon-class="formRename" style="margin-left: 4px;" @click="onClickNameEvent" /> -->
            </div>
          </div>
          <div class="create-nav-center">
            <ul class="tab-menu">
              <li @click="onTabClick(1)" :class="{active:cur==1}">基本信息</li>
              <li @click="onTabClick(2)" :class="{active:cur==2}">页面设计</li>
              <li @click="onTabClick(3)" :class="{active:cur==3}">扩展设置</li>
            </ul>
          </div>
          <div class="create-nav-right">
            <!-- <el-button @click="onPreviewClick" size="mini">预览</el-button> -->
            <!-- <el-button type="warning" @click="onCreateView()" v-if="true">创建视图</el-button> -->
            <el-button type="warning" @click="onConfirmSaveClick()" :disabled="isFocus || saveBtnClick">保存</el-button>
            <el-button type="primary" @click="onConfirmReleaseClick" :disabled="isFocus || saveBtnClick" v-show="isNeedRelease">发布</el-button>
          </div>
        </div>

      </div>
      <div class="form-new-design-wrapper" style="width:100%">
        <div class="form-new-design app-inner-container" style="width:100%;height: 100%;">
          <div v-show="cur == 1" style="height:100%;">
            <info ref="designFormInfo" :formId="formId" :userType="userInfo.type" :agencyId="userInfo.adminAgencyId" @saveBtnClick="getMsgFormSon" :onFormDetail="formDetailCallback" />
          </div>
          <div v-show="cur == 2" style="height:100%;background-color:white;border-radius:4px">
            <v-form-designer ref="vformDesigner" :optionData="optionData"></v-form-designer>
          </div>
          <div v-show="cur == 3" style="height:100%;">
            <set ref="designFormSet" :formId="formId" :isEdit="isEdit" :agencyId="userInfo.adminAgencyId" />
          </div>
        </div>
      </div>
    </div>
    <fillSelector ref="fillSelector" :optionData="optionData"></fillSelector>
    <userSelect ref="userSelect" :callback="userSelectCallback"></userSelect>
  </div>

</template>

<script>
import Info from './components/info'
import Design from './components/design'
import Set from './components/set'
import { formDetail, formName, httpFormRelease, httpGetFormFieldV2, httpSaveFormFieldV2, getTagListByUserId, deleleteFile, saveFormAutocodeRule } from '@/api/system/form'
import { agencyAdmin } from '@/api/system/agency'
import { setFormPostObject } from '@/utils/VFormDesignerHelper.js'

import { aliUploader } from '@/utils/oss'

import { getAgency, getAgencyUser, agencyUserList, agencyDeptList, agencyRoleList } from '@/api/system/agency'
import { userAgencyMyList } from '@/api/system/user'
import { getSessionStorageObj } from '@/utils/db'
import { deepClone } from '@/utils/'

import VFormViewer from './components/vFormViewer/index.vue'
import fillSelector from './components/fillSelector'
import userSelect from '@/views/system/mechan/components/user-select.vue'


import designMixins from './mixins/designMixins'

import { addView } from '@/api/system/views.js'
export default {
  name: 'Edit',
  mixins: [designMixins],
  components: {
    Info,
    Design,
    Set,
    fillSelector,
    userSelect
  },
  data () {
    return {
      logoImg: 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo.png',
      cur: 1,
      userInfo: {},
      formId: null,
      formVer: 2,
      formDetail: {},
      name: '未命名表单',
      width: 0,
      formFieldList: [],
      reserveList: [],
      isFromDetail: false,
      check: '',
      createTime: '',
      createName: '',
      isFocus: false,
      isEdit: true,
      isInput: false,
      saveBtnClick: false,
      projectId: 0,
      isNeedRelease: false,
      isClickSaveBtn: false, // 是否点击保存按钮
      isClickBackBtn: false, // 是否点击返回按钮
      isClickTabBtn: false, // 是否点击tab按钮
      isSaveAndReleaseBtn: false, // 是否是保存并发布
      formInfoCur: null,
      formJson: {},
      formData: {},
      optionData: {},
      userSelectCallback: null
    }
  },
  // 因为 element ui 的input 是 通过 div 来封装的 所以  用 原生的 input获取焦点的方法是不可行的，需要通过 自定义局部命令来实现
  directives: {
    focus: {
      inserted: function (el) {
        el.children[0].focus()
      }
    }
  },
  computed: {},
  mounted () {
    this.name = this.decode(this.$route.query.name)
  },
  created () {
    let sessionStorageUser = getSessionStorageObj('users')
    // this.userInfo = sessionStorageUser
    this.$store.dispatch('GetUserInfo').then((res) => {
      this.userInfo = res.data
    })
    this.formId = this.decode(this.$route.params.id)

    const userToken = this.cookie.get('User-Token')
    this.optionData = {
      userToken: userToken,
      apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
      // apiUrl:`http://localhost:9001`,
      formId: this.formId
    }
    this.optionData.formDataSelectHandler = this.showDataSelect
    this.optionData.formDataSelectAutoFillHandler = this.showDataAutoSelect
    this.optionData.formDataSelectMultiFillHandler = this.showDataMultiSelect;
    this.optionData.formDataViewHandler = this.showDataView
    this.optionData.formDataCardRender = this.dataCardRender()
    this.optionData.formAgencyId = this.formAgencyId
    this.optionData.userSelectHandler = this.showUserSelect
    this.optionData.formCreateUser = sessionStorageUser
    this.optionData.loadAgencyUserExtendFieldsHandler = getAgency
    this.optionData.loadAgencyUserListHandler = agencyUserList
    this.optionData.loadAgencyUserHandler = getAgencyUser
    this.optionData.api_agency = { agencyUserList, agencyDeptList, agencyRoleList, userAgencyMyList }
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW
    this.optionData.formData = {};

    this.optionData.isUserAdmin = getSessionStorageObj('users').type > 1

    this.optionData.aliOSSUpload = aliUploader
    this.optionData.aliOSSUploadConfig = {  //OK
      formId: this.formDetail.formId || 'formId',
      agencyId: this.formDetail.agencyId,
      userId: sessionStorageUser.userId || 'userId',
      uploadPath: 'fillFile/{agencyId}/{formId}/{userId}/{fieldName}/',
    }


    if (!this.isBlank(this.$route.query.type)) {
      this.isFromDetail = true
    }

    if (!this.isBlank(this.$route.query.projectId)) {
      this.projectId = this.decode(this.$route.query.projectId)
    }

    // 读取页面设计器的设计
    httpGetFormFieldV2(this.formId).then((response) => {
      if (response.data) {
        const formDataObject = {
          formConfig: response.data.formConfig,
          widgetList: response.data.widgetList,
          fieldList: response.data.fieldList
        }
        // //设置表单配置
        this.$nextTick(() => {
          setFormPostObject.call(this, formDataObject)
        })
        // this.history.pageDesign= deepClone(formDataObject);
      }
    })
  },
  computed: {
    formAgencyId () {
      return this.formDetail.agencyId;
    },
  },
  methods: {
    // 子组件读取到formDetail的回调，避免多次读取
    formDetailCallback (data) {
      this.formDetail = data;

      //给aliOSS设置agencyId
      this.optionData.aliOSSUploadConfig.formId = this.formDetail.formId
      this.optionData.aliOSSUploadConfig.agencyId = this.formDetail.agencyId
      this.optionData.formAgencyId = this.formDetail.agencyId
      this.optionData.fileDelete = this.fileDelete

      if (data.status == '1') {
        this.isNeedRelease = true
      }
    },
    // 点击修改名称 input输入框显示
    onClickNameEvent () {
      this.isInput = true
    },
    // 监听表单名称修改
    oninputName (value) {
      const self = this
      self.name = value
    },
    // 监听表单名称修改 获取焦点
    onFocusName () {
      this.$refs.nameInput.focus()
      this.$refs.nameInput.select()
      this.isFocus = true
    },
    // 监听表单名称修改 失去焦点
    onBlurName () {
      const _this = this
      if (_this.isBlank(this.name)) {
        _this.name = '未命名表单'
        _this.width = 90
      }
      if (this.name.length > 50) {
        this.$message({
          type: 'warning',
          message: '表单名称仅支持输入1-50个字！'
        })
        return
      }
      const param = {
        formId: _this.formId,
        name: _this.name
      }
      formName(JSON.stringify(param)).then((response) => {
        if (response.code == 200) {
          _this.msgSuccess('修改表单名称成功')
          _this.isInput = false
          _this.isFocus = false
        }
      })
    },

    onFormInfoTabClick (index) {
      this.formInfoCur = index
    },
    getMsgFormSon (data) {
      console.log("getMsgFormSon", data);
      this.saveBtnClick = data
    },
    onSavePrompt (saveCallback, noSaveCallback) {
      this.$confirm('表单信息有修改，是否保存？', '提示', {
        confirmButtonText: '保存并继续',
        cancelButtonText: '不保存',
        type: 'warning',
        distinguishCancelAndClose: true // 区分取消与关闭
      })
        .then(() => {
          saveCallback()
        })
        .catch((actionS) => {
          if (actionS == 'cancel') {
            noSaveCallback()
          } else {
            // 如果点击的是右上角关闭，则什么都不做
          }
        })
    },
    // 新版 --- 创建表单逻辑
    onTabClick (index) {
      this.cur = index
    },
    // 保存表单基本信息
    saveFormBase (callback) {
      this.$refs.designFormInfo.saveFormBase(
        {
          name: this.name,
          projectId: this.projectId
        },
        (formId) => {
          this.formId = formId
          if (callback) callback(this.formId)
        }
      )
    },
    // 保存表单页面设计
    saveFormFieldV2 (callback) {
      const _this = this
      // 新表单保存逻辑
      const fromPost = {
        projectId: this.projectId,
        formId: this.isBlank(this.formId) ? 0 : this.formId,
        ...this.$refs.vformDesigner.getFormJson()
      }
      httpSaveFormFieldV2(fromPost).then((response) => {
        if (response.code === 200) {
          if (callback) callback(this.formId)
        } else {
          _this.msgError(response.msg)
        }
      })
    },

    // 保存表单提交设置
    saveFormSubmit (succ, fail) {
      this.$refs.designFormSet.saveFormSubmit(succ, fail)
    },
    // 点击保存按钮事件
    onConfirmSaveClick (callback) {
      const _this = this
      const actions = []
      const isEditCur = this.checkChangedPage()
      const savePage1 = () => {
        if (!isEditCur['1']) {
          return Promise.resolve(true)
        } else {
          return new Promise((resolve, reject) => {
            _this.saveFormBase(() => {
              resolve()
            })
          })
        }
      }
      const savePage2 = () => {
        if (!isEditCur['2']) {
          return Promise.resolve(true)
        } else {
          return new Promise((resolve, reject) => {
            _this.saveFormFieldV2(() => {
              _this.$refs.vformDesigner.saveFormDataChooseRelation().then((res)=>{
                resolve()
              })
            })
          })
        }
      }
      const savePage3 = () => {
        if (!isEditCur['3']) {
          return Promise.resolve(true)
        } else {
          return new Promise((resolve, reject) => {
            _this.saveFormSubmit(
              () => {
                resolve()
              },
              () => {
                reject()
              }
            )
          })
        }
      }
      const saveAutoCode = ([page1Res, page2Res, page3Res]) => {

        const fieldList = this.$refs.vformDesigner.getFieldWidgets();
        const autocode = fieldList.find(x => x.field && x.field.subtype === 'autocode')
        if (!autocode) {
          return new Promise((resolve, reject) => {
            // 缺保存自动编号的接口
            saveFormAutocodeRule({
              formId: this.formId,
              autoCodeRule: '',
              autocodeStatus: false,
            }).then(() => {
              resolve()
            }).catch(() => {
              reject()
            })
          })
        }
        return new Promise((resolve, reject) => {

          if (!this.formId) { Promise.resolve(); return; }
          // 缺保存自动编号的接口
          saveFormAutocodeRule({
            formId: this.formId,
            autoCodeRule: JSON.stringify(autocode.field.options.coderule),
            autocodeStatus: autocode.field.options.counterReset || false
          }).then(() => {
            resolve()
          }).catch(() => {
            reject()
          })
        })
      }

      //TODO: 增加视图修改
      Promise.all([savePage1(), savePage2(), savePage3()])
        .then((values) => {
          this.setViewList(this.formId);
          saveAutoCode(values).then(_ => {
            if (callback) callback()
            else _this.msgSuccess('保存成功')
          })
        })
        .catch((reason) => {
          console.error(reason)
        })
    },
    // 点击发布按钮事件
    onConfirmReleaseClick () {
      const _this = this
      _this.onConfirmSaveClick(() => {
        // _this.httpFormRelease()
        this.httpFormReleaseAndCreateView(this.formId)
      })
    },
    // 新版 --- 发布请求
    httpFormRelease () {
      const _this = this
      const param = {
        formId: _this.formId
      }
      return new Promise((resolve,reject)=>{
      httpFormRelease(JSON.stringify(param)).then((response) => {
        if (response.code === 200) {
          _this.msgSuccess('发布成功')
          _this.isNeedRelease = false          
          // _this.$refs.designFormInfo.isEdit = false;
          // _this.$refs.designFormInfo.recoverHistory()
          // _this.$refs.vformDesigner.isDesignSaved(true)
          // _this.$refs.designFormSet.isSetEdit = false
          // _this.isSaveAndReleaseBtn = false
          // _this.isSaveAndReleaseBtn = false
          resolve();
        } else {
          _this.msgError(response.msg)
          reject()
        }
      })
      })
    },
    // 获取页面修改情况
    checkChangedPage () {
      const isEditCur = {
        1: this.$refs.designFormInfo.isEdit,
        2: !this.$refs.vformDesigner.isDesignSaved(),
        3: this.$refs.designFormSet.isSetEdit
      }
      return isEditCur
    },

    // 新版 --- 返回
    onGoBackClick () {
      const _this = this
      const actions = []
      let isEdit = false
      const isEditCur = this.checkChangedPage()

      Object.keys(isEditCur).forEach((x) => {
        isEdit = isEdit || isEditCur[x]
      })

      if (isEdit) {
        _this.onSavePrompt(
          (/* 保存函数*/) => {
            _this
              .onConfirmSaveClick(() => {
                _this.msgSuccess('保存成功')
                _this.$router.go(-1)
              })
              .catch((reason) => {
                console.error(reason)
              })
          },
          (/* 不保存函数*/) => {
            _this.$router.go(-1)
          }
        )
      } else {
        _this.$router.go(-1)
      }
    },
    showDataSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.show(fillSelectOption, callback)
    },
    showDataAutoSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.autoFill(fillSelectOption, callback)
    },
    showDataMultiSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.autoFillMulti(fillSelectOption, callback);
    },
    async showDataView (fillSelectOption, fillData, callback) {
      // callback:选择完成后的回调
      // 加载表单配置数据
      const res = await httpGetFormFieldV2(fillSelectOption.formId)

      this.$refs.dlgvFormViewer.showView({
        fillId: fillData.fillId,
        formId: fillSelectOption.formId,
        formJson: res.data,
        formData: fillData,
        showHiddenWidgets: this.cur != 3
      })
    },
    // 引用table的render，显示dataCard内容
    dataCardRender () {
      const renders = require('@/utils/gridrender/cellRender')
      return renders.renderProvider
    },
    showUserSelect (selectedUserList, fieldOptions, callback) {
      this.$refs.userSelect.showDialog(selectedUserList, fieldOptions);
      this.userSelectCallback = callback;
    },
    fileDelete (file) {
      deleleteFile(file.fileId).then(res => {

      })
      if (file.thumbFileId) {
        deleleteFile(file.thumbFileId).then(res => {
        })
      }
    },
  }
}
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
.create-container {
  width: 100%;
  margin: 0 auto;
  position: fixed;

  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;
  .form-new-design-wrapper {
    // position: absolute;
    // top: 76px;
    // bottom: 20px;
    // left: 20px;
    // right: 20px;
    margin-top: 20px;
    text-align: center;

    flex-shrink: 1;
    overflow: hidden;
    display: inline-block;
    overflow: auto;
  }

  .form-new-design {
    margin-top: 10px;

    display: inline-block;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 0px;

    .container-center {
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      justify-content: center;
    }
  }

  .create-nav-wrapper {
    width: 100%;
    height: 56px;
    background: #fff;
    -webkit-transform: translateZ(0);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }
  .create-nav {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    height: 56px;
    margin: auto;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    .create-nav-left {
      height: 56px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: left;
      .nav-home {
        cursor: pointer;
        font-size: 20px;
      }
      .separator {
        width: 1px;
        height: 20px;
        border-right: 1px solid #dbdde7;
        margin: 0 16px;
      }
      .nav-title {
        max-width: 350px;
        font-size: 16px;
        font-weight: 500;
        color: #646d7a;
        line-height: 22px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        border-bottom: dashed 1px #bababa;
      }
    }
    .create-nav-center {
      position: relative;
      display: inline-table;
      margin: 0 auto;
      .tab-menu {
        list-style: none;
        margin: 0;
        padding: 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 56px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        li {
          font-size: 16px;
          color: #333333;
          display: inline-block;
          width: auto;
          height: auto;
          text-align: center;
          vertical-align: middle;
          line-height: 56px;
          border: 0;
          cursor: pointer;
          display: inline-block;
          float: left;
          padding: 0 10px;
          margin: 0 7px;
        }
        .active {
          color: #3476f0;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 52px;
            height: 4px;
            bottom: -0.5px;
            left: 50%;
            margin-left: -26px;
            background: #3476f0;
            border-radius: 3px 3px 0 0;
          }
        }
        .ul-step__arrow {
          display: inline-block;
          margin: 0 60px;
          span {
            display: inline-block;
            width: 20px;
            height: 40px;
            background: url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/form-next.png')
              no-repeat center center;
            background-size: 18px 12px;
          }
        }
      }
    }
    .create-nav-right {
      width: 300px;
      flex-shrink: 0;
      text-align: right;
      padding-right:10px;
      box-sizing: border-box;
    }
  }
  .details-nav-center {
    width: 100%;
    margin: auto;
    padding: 0;
    text-align: center;
    background: #f5f7fa;
    li {
      display: inline-block;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      margin-right: 60px;
      font-size: 14px;
      color: #bababa;
      &.active {
        color: #394349;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100px;
          left: 50%;
          bottom: 0;
          height: 4px;
          background: #0078f8;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
          z-index: 1;
          margin-left: -50px;
        }
      }
    }
  }
  // .form-new-design {
  //     // width: 100%;
  //     margin-top: 10px;
  //     position: absolute;
  //     top: 70px;
  //     bottom: 20px;
  //     left: 20px;
  //     right: 20px;
  //     box-sizing: border-box;
  //     overflow-y: auto;
  //     overflow-x: hidden;

  //     .container-center {
  //         display: flex;
  //         display: -webkit-flex;
  //         display: -moz-flex;
  //         display: -ms-flex;
  //         display: -o-flex;
  //         justify-content: center;
  //     }
  // }
  .form-img {
    width: 300px;
    height: 180px;
    img {
      width: 100%;
      max-width: 100%;
      height: 180px;
      border-radius: 6px;
    }
  }
  .form-info {
    width: 100%;
    position: relative;
    .form-title {
      width: 100%;
      font-size: 22px;
      color: #333333;
      margin: 0;
      line-height: 30px;
      font-weight: 400;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      align-items: center;
    }
    .form-date {
      width: 100%;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      margin: 0;
      color: #909090;
      .fill-date {
        margin: 0;
        padding: 0;
        .is-agency-icon {
          display: inline-block;
          width: 32px;
          height: 12px;
          margin-left: 5px;
          vertical-align: -1px;
        }
      }
    }
  }
  .details-title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    .title-span {
      font-size: 14px;
      color: #404040;
      line-height: 20px;
    }
  }
  .form-field-list {
    margin: 0 auto;
    width: 710px;
    padding: 20px 0;
    .form-field-item {
      width: 100%;
      margin-bottom: 20px;
      .label-title {
        display: inline-block;
        width: 100%;
        font-size: 13px;
        font-weight: 600;
        color: #394349;
        line-height: 18px;
        padding-bottom: 10px;
      }
    }
  }
}

.title_editor_input {
  width: 100%;
  float: left;
  display: inline-block;
  font-size: 14px !important;
}
.title_editor_input.el-input--medium .el-input__inner {
  width: 100%;
  height: 28px;
  line-height: 28px;
}
</style>


var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "create-container" }, [
        _c("div", { staticClass: "create-nav-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "create-nav app-inner-container",
              staticStyle: { "border-bottom": "1px solid #f5f7fa" },
            },
            [
              _c("div", { staticClass: "create-nav-left" }, [
                _c(
                  "div",
                  { staticClass: "nav-home", on: { click: _vm.onGoBackClick } },
                  [_c("svg-icon", { attrs: { "icon-class": "home" } })],
                  1
                ),
                _c("div", { staticClass: "separator" }),
                _c(
                  "div",
                  { staticClass: "nav-title" },
                  [
                    _vm.isInput
                      ? _c("el-input", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          ref: "nameInput",
                          staticClass: "title_editor_input",
                          attrs: { placeholder: "请输入表单名称" },
                          on: {
                            input: _vm.oninputName,
                            focus: _vm.onFocusName,
                            blur: _vm.onBlurName,
                          },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        })
                      : _c(
                          "span",
                          {
                            ref: "fixName",
                            staticStyle: { color: "#3476F0" },
                            on: { click: _vm.onClickNameEvent },
                          },
                          [_vm._v(_vm._s(_vm.name))]
                        ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "create-nav-center" }, [
                _c("ul", { staticClass: "tab-menu" }, [
                  _c(
                    "li",
                    {
                      class: { active: _vm.cur == 1 },
                      on: {
                        click: function ($event) {
                          return _vm.onTabClick(1)
                        },
                      },
                    },
                    [_vm._v("基本信息")]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.cur == 2 },
                      on: {
                        click: function ($event) {
                          return _vm.onTabClick(2)
                        },
                      },
                    },
                    [_vm._v("页面设计")]
                  ),
                  _c(
                    "li",
                    {
                      class: { active: _vm.cur == 3 },
                      on: {
                        click: function ($event) {
                          return _vm.onTabClick(3)
                        },
                      },
                    },
                    [_vm._v("扩展设置")]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "create-nav-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "warning",
                        disabled: _vm.isFocus || _vm.saveBtnClick,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onConfirmSaveClick()
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isNeedRelease,
                          expression: "isNeedRelease",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        disabled: _vm.isFocus || _vm.saveBtnClick,
                      },
                      on: { click: _vm.onConfirmReleaseClick },
                    },
                    [_vm._v("发布")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "form-new-design-wrapper",
            staticStyle: { width: "100%" },
          },
          [
            _c(
              "div",
              {
                staticClass: "form-new-design app-inner-container",
                staticStyle: { width: "100%", height: "100%" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.cur == 1,
                        expression: "cur == 1",
                      },
                    ],
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c("info", {
                      ref: "designFormInfo",
                      attrs: {
                        formId: _vm.formId,
                        userType: _vm.userInfo.type,
                        agencyId: _vm.userInfo.adminAgencyId,
                        onFormDetail: _vm.formDetailCallback,
                      },
                      on: { saveBtnClick: _vm.getMsgFormSon },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.cur == 2,
                        expression: "cur == 2",
                      },
                    ],
                    staticStyle: {
                      height: "100%",
                      "background-color": "white",
                      "border-radius": "4px",
                    },
                  },
                  [
                    _c("v-form-designer", {
                      ref: "vformDesigner",
                      attrs: { optionData: _vm.optionData },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.cur == 3,
                        expression: "cur == 3",
                      },
                    ],
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c("set", {
                      ref: "designFormSet",
                      attrs: {
                        formId: _vm.formId,
                        isEdit: _vm.isEdit,
                        agencyId: _vm.userInfo.adminAgencyId,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("fillSelector", {
        ref: "fillSelector",
        attrs: { optionData: _vm.optionData },
      }),
      _c("userSelect", {
        ref: "userSelect",
        attrs: { callback: _vm.userSelectCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }